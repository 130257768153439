(function() {
	'use strict';
	var $ = avm.require('jquery'),
		detectedPlatform,
		platformName,
		defaultPlatform,
		supportedPlatforms,
		availablePlatforms,
		$tryBox = $('.js-try-box');

	// if no try box on page, abort
	if ($tryBox.length === 0) {
		return;
	} else {
		detectedPlatform = avastGlobals.detect.os;
		platformName = detectedPlatform.name.toLowerCase();
		defaultPlatform = 'windows';
		supportedPlatforms = [defaultPlatform, 'mac'];
		availablePlatforms = ['windows', 'mac', 'android', 'ios'];
	}

	// Init
	$tryBox.each(function(index) {
		var self = this,
			$self = $(self),
			settings = $self.data().settings;

		initializeTryBox(index, $self, settings);
	});
	// /Init

	function initializeTryBox(index, $self, settings) {
		var $tryBoxToggler = $self.find('.js-try-box-toggler');

		// there are no settings, initialize default behavior : toggler + switch according to platform (windows is default)
		if (settings === null) {
			showContentByPlatform($self, $tryBoxToggler);
			handleToggler($self, $tryBoxToggler);

		// read settings and initialize according to them
		} else {
			settings.display_toggler 				= settings.display_toggler === 'true' ? true : false;
			settings.enable_platform_detection 		= settings.enable_platform_detection === 'true' ? true : false;
			defaultPlatform 						= settings.default_platform;

			if (settings.display_toggler) {
				handleToggler($self, $tryBoxToggler);
			} else {
				$self.find('.js-try-box-toggler-container').hide();
				$self.find('.js-content-instead-toggler').removeClass('hide').html(settings.content_instead_toggler.replace('i/', getStaticPath() + 'i/'));

				showContentByPlatform($self, null)
			}
		}
	}

	// display content based on platform
	function showContentByPlatform($tryBox, $tryBoxToggler) {
		if (availablePlatforms.indexOf(platformName) > -1) {
			if ($tryBoxToggler !== null) {
				$tryBoxToggler.val(platformName);
			}

			if ($tryBox.find('.js-try-' + platformName).length > 0) {
				$tryBox.find('.js-try-' + platformName).addClass('active');
			}
		} else {
			// Default state if other platform detected
			setDefaultState($tryBox, $tryBoxToggler);
		}

		function setDefaultState($tryBox) {
			if (supportedPlatforms.indexOf(platformName) === -1) {
				// platform not supported, show default platform
				$tryBox.find('.js-try-button').removeClass('active');
				$tryBox.find('.js-try-' + defaultPlatform).addClass('active');

				if ($tryBoxToggler !== null) {
					$tryBoxToggler.val(defaultPlatform);
				}
			}
		}
	}

	function handleToggler($tryBox, $tryBoxToggler) {
		$tryBoxToggler.on('change', function() {
			var currentValue = $(this).val();

			$tryBox.find('.js-try-button').removeClass('active');
			$tryBox.find('.js-try-' + currentValue).addClass('active');
		});
	}

	function getStaticPath() {
		var path = $('link[href*=".css"]').attr('href');
		return path.split('c/')[0];
	}
})();
